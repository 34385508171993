// Routes to be send page to pageView to save user path
export const Routes = {
  CoverType: "CT", // QuestionOne
  TravellingFrom: "TF", // QuestionTwo
  TravellingTo: "TT", // QuestionThree
  Cruise: "CR", // QuestionFour
  TravelDates: "TD", // QuestionFive
  CoverFor: "CF", // QuestionSix
  Organiser: "OR", // QuestionSeven
  Traveller: "TR", // QuestionEight
  MedicalConfirmation: "MCF", // QuestionMedicalConfirmation
  MedicalConditions: "MCD", // QuestionMedicalConditions
  MedicalTreatments: "MT", // QuestionMedicalTreatments
  TravellerHealth: "TH", // MedicalLandingPage
  MedicalDashboard: "MD", // MedicalDashboard
  ContactDetails: "CD", // ContactDetails
  Quote: "QT", // Quote
  QuoteReview: "QR", // QuoteReview
};
