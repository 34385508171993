import React from "react";

import Styles from "./btn-loading-svg.module.css";

type Props = {
  color?: string;
  size?: number;
};

function BtnLoadingSvg({ color = "#272427", size = 24 }: Readonly<Props>) {
  return (
    <svg
      className={`${Styles.loader}`}
      width={`${size}`}
      height={`${size}`}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Loader">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.75 2C12.75 1.58579 12.4142 1.25 12 1.25C11.5858 1.25 11.25 1.58579 11.25 2V6C11.25 6.41421 11.5858 6.75 12 6.75C12.4142 6.75 12.75 6.41421 12.75 6V2ZM12.75 18C12.75 17.5858 12.4142 17.25 12 17.25C11.5858 17.25 11.25 17.5858 11.25 18V22C11.25 22.4142 11.5858 22.75 12 22.75C12.4142 22.75 12.75 22.4142 12.75 22V18ZM4.3996 4.39985C4.69249 4.10695 5.16737 4.10695 5.46026 4.39985L8.29026 7.22985C8.58316 7.52274 8.58316 7.99761 8.29026 8.29051C7.99737 8.5834 7.5225 8.5834 7.2296 8.29051L4.3996 5.46051C4.10671 5.16761 4.10671 4.69274 4.3996 4.39985ZM16.7703 15.7099C16.4774 15.417 16.0026 15.417 15.7097 15.7099C15.4168 16.0028 15.4168 16.4777 15.7097 16.7706L18.5397 19.6006C18.8326 19.8935 19.3074 19.8935 19.6003 19.6006C19.8932 19.3077 19.8932 18.8328 19.6003 18.5399L16.7703 15.7099ZM1.25 12C1.25 11.5858 1.58579 11.25 2 11.25H6C6.41421 11.25 6.75 11.5858 6.75 12C6.75 12.4142 6.41421 12.75 6 12.75H2C1.58579 12.75 1.25 12.4142 1.25 12ZM18 11.25C17.5858 11.25 17.25 11.5858 17.25 12C17.25 12.4142 17.5858 12.75 18 12.75H22C22.4142 12.75 22.75 12.4142 22.75 12C22.75 11.5858 22.4142 11.25 22 11.25H18ZM8.29026 15.7099C8.58316 16.0028 8.58316 16.4777 8.29026 16.7706L5.46026 19.6006C5.16737 19.8935 4.69249 19.8935 4.3996 19.6006C4.10671 19.3077 4.10671 18.8328 4.3996 18.5399L7.2296 15.7099C7.5225 15.417 7.99737 15.417 8.29026 15.7099ZM19.6003 5.46051C19.8932 5.16761 19.8932 4.69274 19.6003 4.39985C19.3074 4.10695 18.8326 4.10695 18.5397 4.39985L15.7097 7.22985C15.4168 7.52274 15.4168 7.99761 15.7097 8.29051C16.0026 8.5834 16.4774 8.5834 16.7703 8.29051L19.6003 5.46051Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default BtnLoadingSvg;
