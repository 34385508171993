import { useEffect, useState } from "react";

import {
  LandingPage,
  MedicalDashboard,
  MedicalScreen,
  SessionSuccessPage,
} from "@/config/app-constant";
import {
  accessPagePreviousURL,
  isLastIndexIncludeSameValue,
  storageAvailable,
} from "@/utils/common.utils";

export interface PageWindow extends Window {
  pageLoadUrls?: string[];
}

const useCustomPageTracking = (page: string) => {
  const [pageReferrer, setPageReferrer] = useState("");
  const [pageUrl, setPageUrl] = useState("");
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    if (typeof window === "undefined") return;

    const { pathname } = window.location;
    const stringWithoutSlashes = pathname.replace(/\//g, "");
    const pageLink = page ? window.location.href : "";
    if (page !== MedicalScreen) {
      sessionStorage.setItem(SessionSuccessPage, window.location.pathname);
    }

    const extendedWindow = window as PageWindow;

    if (extendedWindow) {
      extendedWindow.pageLoadUrls = extendedWindow.pageLoadUrls ?? [];
      if (
        !isLastIndexIncludeSameValue(
          extendedWindow.pageLoadUrls,
          window.location.href
        )
      ) {
        extendedWindow.pageLoadUrls.push(window.location.href);
      }
    }

    let pageRef = accessPagePreviousURL(extendedWindow);

    if (stringWithoutSlashes && stringWithoutSlashes === MedicalDashboard) {
      if (storageAvailable()) {
        const veriskObj = sessionStorage.getItem("veriskObj");
        if (veriskObj) {
          const parsedObj = JSON.parse(veriskObj);
          if (parsedObj.hookFlash) {
            pageRef = parsedObj.veriskUri || "";
            sessionStorage.removeItem("veriskObj");
          }
        }
      }
    }

    setPageReferrer(pageRef ?? "");
    setPageUrl(pageLink);
    setPageTitle(
      stringWithoutSlashes !== "" ? stringWithoutSlashes : LandingPage
    );
  }, [page]);

  return {
    pageReferrer,
    pageUrl,
    pageTitle,
  };
};

export default useCustomPageTracking;
