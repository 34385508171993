import parse from "html-react-parser";
import React from "react";

import Styles from "./rich-text.module.css";

interface RichTextProps {
  richText: string;
  className?: string;
}

export default function RichText({
  richText,
  className = "",
}: Readonly<RichTextProps>) {
  return (
    <div data-testid="richText-testid">
      {/* <i className="fas fa-check mr-4 mt-2 text-l text-orange"></i> */}
      {richText && (
        <div className={`${Styles.richText} ${className}`}>
          {parse(richText)}
        </div>
      )}
    </div>
  );
}
